import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography, Button } from "@mui/material";
import ClientCard from "./ClientCard";
import NumberOfUsers from "./NumberOfUsers";
import ProductCard from "./ProductCard";
import QuoteCardList from "./QuoteCard";
import { ChartsData } from "./LineChart/ChartsData";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchChartsData,
  fetchClientDashboard,
  fetchPieChartsData,
  fetchProductDashboard,
  fetchQuoteDashboard,
  fetchUserDashboard,
} from "../../actions/dashboardAction";
import PieChartsData from "./PieChart/PieChartsData";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  // console.log("auth", auth?.user?.userType);
  const admin = auth?.user?.userType === "ADMIN";
  const owner = auth?.user?.userType === "OWNER";
  const dispatch = useDispatch();
  useEffect(() => {
    if (owner || admin) {
      try {
        dispatch(fetchQuoteDashboard());
        dispatch(fetchUserDashboard());
        dispatch(fetchClientDashboard());
        dispatch(fetchProductDashboard());
        dispatch(fetchChartsData());
        dispatch(fetchPieChartsData());
      } catch (error) {
        // console.log(error);
      }
    } else {
      // console.log("No Data");
    }
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Dashboard</title>
      </Helmet>
      <Grid sx={{ height: "120vh", pt: 10 }}>
        {(owner || admin) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ClientCard />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NumberOfUsers />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ProductCard />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QuoteCardList />
            </Grid>
            <Grid container xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={6} sx={{ mt: 4 }}>
                <ChartsData />
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{ mt: 10 }}>
                <PieChartsData />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!owner && !admin && (
          <Grid sx={{ mt: 2 }}>
            <Typography>Quick Actions</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate("/quote-create");
              }}
            >
              <AddIcon />
              Create New Quote
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate("/app/mgmt/customer/new-customer");
              }}
            >
              <AddIcon />
              Add Clients
            </Button>
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;
